body {
  text-align: center;
  /* background-image: url("../media/bg.jpg");
  background-size: cover; */
  background-color: #262626;
  color: white;
  /* position: fixed; */
  max-height: 100vh;
  max-width: 100vw;
  height: 100vh;
  width: 100%;
}

@media screen and (max-height: 1000px) {
  .wrapper {
    padding-top: 0px !important;
    height: 100%;
  }
  .small-font {
  /* font-size: 2vh !important; */
  overflow: auto;
  }
}
a {
  color: #bb86fc;
}

a:visited {
  color: #bb86fc;
}

#root {
  /* height: 100vh; */
  padding: 50px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
}

.phototransform {
  width: 100% !important;
  height: 100% !important;
}

.h-100
{
  height: 100%;
}

.nomargin-x
{
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.btn {
  background-color: #bb86fc;
  font-weight: bold;
  color: black;
  margin: 16px;
  font-size: 24px;
  padding: 16px 54px;
  border-radius: 30px;
  border: 1px solid transparent;
  cursor: pointer;
}

.btn-txt {
  background-color: transparent;
  color: #bb86fc;
  border: 1px solid transparent;

}

.btn-txt:hover {
  background-color: transparent !important;
  border: 1px solid #a869f5;
}

.btn:hover
{
  background-color: #a869f5;
}

.close {
  position: absolute;
  right: 25px;
  background-color: #545959;
  font-weight: bold;
  font-size: 2em;
  color: white;
  width: 50px;
  height: 50px;
  border: 3px black solid;
  border-radius: 25px;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: baseline;
}